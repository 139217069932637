// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".UU_ugPq49Xh2yqZgKrUn {\n    display: grid;\n    justify-content: center;\n    align-items: center;\n\n    height: 6rem;\n    width: 6rem;\n\n    user-select: none;\n\n    cursor: pointer;\n    outline: none;\n}\n\n.GrdxDId15E_pexZ6xJD8 {\n    background-color: var(--button-icon-bg-color-disabled);\n    cursor: not-allowed;\n}\n", "",{"version":3,"sources":["webpack://./base/components/button-icon/styles.module.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,uBAAuB;IACvB,mBAAmB;;IAEnB,YAAY;IACZ,WAAW;;IAEX,iBAAiB;;IAEjB,eAAe;IACf,aAAa;AACjB;;AAEA;IACI,sDAAsD;IACtD,mBAAmB;AACvB","sourcesContent":[".button {\n    display: grid;\n    justify-content: center;\n    align-items: center;\n\n    height: 6rem;\n    width: 6rem;\n\n    user-select: none;\n\n    cursor: pointer;\n    outline: none;\n}\n\n.buttonDisabled {\n    background-color: var(--button-icon-bg-color-disabled);\n    cursor: not-allowed;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"button": "UU_ugPq49Xh2yqZgKrUn",
	"buttonDisabled": "GrdxDId15E_pexZ6xJD8"
};
export default ___CSS_LOADER_EXPORT___;
