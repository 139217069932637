import React from "react";

import classNames from "@/base/lib/class-names.js";

import DivButton from "@/base/components/div-button/index.js";

import styles from "./styles.module.css";


const ButtonIcon = (props) => {
    const onClick = (evt) => {
        if (props.isDisabled) {
            return;
        }

        props.onClick(evt);
    };

    /* --- */

    const renderIcon = () => {
        if (props.isPressed) {
            return props.iconPressed;
        }

        return props.icon;
    };

    /* --- */

    const buttonClassName = classNames({
        [styles.button]: true,
        [styles.buttonDisabled]: props.isDisabled,
    });

    return (
        <DivButton
            className={buttonClassName}
            onClick={onClick}
        >
            {renderIcon()}
        </DivButton>
    );
};

ButtonIcon.defaultProps = {
    icon: null,
    iconPressed: false,
    onClick: () => { },
    isPressed: false,
    isDisabled: false,
};

export default ButtonIcon;
